$assetPath: '../../assets/images/common';
.bg {
  background-image: url(#{$assetPath}/web.png);
  background-color: var(--ion-background);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .card {
    // background-color: var(--ion-background-secondary);
    background: linear-gradient(
      -115deg,
      rgba(var(--ion-color-primary-rgb), 0.7) -15%,
      rgba(var(--ion-background-secondary-rgb), 0.4) 45%,
      rgba(255, 255, 255, 0.2) 101%
    );
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    min-height: 450px;
    width: 650px;

    .logo-div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
      padding: 15px 10px;
      .border {
        width: 100%;
        border-right: 1px solid var(--input-background-color);
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo {
          width: var(--login-xl-logo-width);
        }
      }
    }
    .login-form {
      justify-content: center;
      width: 60%;
      padding-right: 30px;

      .title {
        color: var(--login-title-color);
      }

      .mob-logo-div {
        display: none;
      }
    }
    .input-label {
      color: var(--input-background-color) !important;
    }
    .MuiInputBase-root {
      border: 1px solid var(--ion-color-primary-contrast);
      background-color: var(--input-background-color) !important;
      padding: auto;
      .MuiInputBase-input {
        // color: var(--ion-color-primary-contrast) !important;
        //background-color: var(--input-background-color) !important;
      }
    }

    .MuiButton-contained {
      background-color: var(--ion-color-primary) !important;
    }
    .MuiButton-label {
      color: var(--ion-color-text-contrast) !important;
      background-color: var(--ion-color-primary) !important;
      font-weight: bold;
      .MuiButton-endIcon {
        color: var(--ion-color-text-contrast) !important;
      }
    }
  }
}
.bg .card .MuiInputBase-root{border: none;}
@media (max-width: 720px) {
  .bg {
    background-image: url(#{$assetPath}/mobile.png);
    background-color: var(--ion-background);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    -moz-background-size: cover;
    -o-background-size: cover;

    .card {
      border-radius: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-size: cover;
      display: block;
      width: 370px;
      min-height: 450px;
      .logo-div {
        display: none;
      }
      .login-form {
        justify-content: center;
        width: 100%;
        padding: 3px;
        .mob-logo-div {
          display: flex;
          justify-content: center;
          padding-top: 20px;
          .border {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;

            .logo {
              max-width: var(--login-sm-logo-width);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .bg {
    background-image: url(#{$assetPath}/smob.png);
    background-color: var(--ion-background);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

    .card {
      border-radius: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-size: cover;
      display: block;
      width: 300px;
      height: 430px;
      .logo-div {
        display: none;
      }
      .login-form {
        justify-content: center;
        width: 100%;
        padding: 3px;
        .mob-logo-div {
          display: flex;
          justify-content: center;
          padding-top: 10px;
          .border {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}
