.change-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .usr-input,
  .pwd-input {
    display: inline-grid;
    margin-top: 36px;
  }

  .submit-form-btn {
    // margin-top: 20px !important;
    margin-bottom: 20px;
    width: 60%;
    max-width: 341px;
    height: 48px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  .accept-terms-input {
    padding-top: 14px;
    max-width: 341px;

    .MuiFormControlLabel-root {
      margin-left: 0px;
      margin-right: 0px;
      align-items: flex-start;

      .MuiFormControlLabel-label {
        margin-left: 10px;
        color: var(--ion-text-color);
      }
    }

    .MuiFormHelperText-root {
      color: red;
    }

    .accept-terms-checkbox {
      color: var(--ion-text-color) !important;
      position: relative !important;
    }
  }
}

.error-msg {
  color: var(--ion-color-danger);
  margin-top: 20px;
  text-align: center;
}
// border-radius: 0px 0px 20px 20px;
.success-msg {
  color: var(--ion-color-success);
  margin-top: 20px;
  text-align: center;
}

.user-name,
.pwd-field {
  max-width: 341px;
  height: 48px;
}

.input-tooltip {
  color: var(--ion-text-color);
  font-size: 20px;
  padding-left: 4px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    width: 298px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .user-name,
  .pwd-field,
  .submit-form-btn {
    width: 250px;
  }
}
