.reports-ctn {
  border-radius: 4px;
  .header-ctn {
    padding: 20px 0px;
    .hedaer-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    .page-title {
      color: var(--page-titles-color);
      font-family: var(--headers-font-family);
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0.01em;
      float: left;
      margin-right: auto;
      // margin-top: 20px;
    }
  }

  .filters-row {
    ion-select, .date-filter, .select-filter, .searchbar-input-container {
      border: 1px solid var(--filter-controls-border);
      --box-shadow: 0;
    }

    .MuiFormControl-marginNormal { 
      margin-top: 0;
    }

    .MuiInputBase-root .MuiInputBase-input {
      padding: 0 4px;
    }

    .filter-label {
      color: var(--ion-plain-text-color);
      font-size: 14px;
    }

    .search-filter {
      max-height: 44px;
      max-width: 228px;
      @media only screen and (max-width: 920px) {
        max-width: 100%;
        max-height: initial;
      }

      .searchbar-input-container {
        border-radius: 3px;
        --box-shadow: 0;
        input {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }

    .MuiFormControl-root, ion-select {
      max-height: 44px;
      max-width: 220px;
    }
  }

  .content-ctn {
    padding-top: 20px;
    background-color: #ffff;
    border-radius: 6px;

    .table-ctn {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableRow-root:nth-child(even) {
              background: var(--ion-card-background-contrast);
            }
            .MuiTableRow-root.back-odd-row {
              background: var(--back-odd-background-light);
              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-dark);
              }
            }
            .MuiTableRow-root.lay-odd-row {
              background: var(--lay-odd-background-light);
              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-dark);
              }
            }
          }
        }
      }
    }
  }

  .header-ctn,
  .content-ctn {
    .points-type-toggle {
      background-color: transparent;
      border: 1.5px solid #636c89;
      display: inline-flex;
      height: 40px;
      border-radius: 4px;
      .MuiBottomNavigationAction-root {
        color: var(--ion-text-color);
        font-weight: 500;
        font-size: 16px;
        padding: 0px !important;
        &.Mui-selected {
          color: var(--ion-text-light);
          background: var(--ion-color-success);
        }

        .MuiBottomNavigationAction-label {
          font-size: 14px !important;
        }
      }
    }
  }

  .tbl-ctn {
    .MuiTableContainer-root {
      .MuiTable-root {
        .MuiTableHead-root {
          background: var(--ion-background) !important;
          .MuiTableCell-head {
            color: #fff !important;
          }
        }
      }
    }
  }

  .tbl-ctn:not(.no-hov-style) {
    .MuiTableContainer-root {
      .MuiTable-root {
        .MuiTableBody-root {
          .MuiTableRow-root:hover {
            background-color: rgba(255,167,36,0.2) !important;
          }
        }
      }
    }
  }

  .no-data-row {
    background: none;
    width: 100%;
    border-bottom: 0px !important;
    color: var(--ion-text-color-contrast);
    height: 40px;
    padding-top: 15px;
    padding-left: 15px;
    font-family: var(--ion-font-family);
  }
}
@media (min-width: 992px) {
  .reports-ctn {
    width: 100%;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 720px) {
  .admin-module-ctn {
    padding-bottom: 25px;
  }

  .reports-ctn {
    margin: 10px 0px !important;
    .header-ctn,
    .content-ctn {
      padding: 10px 0px;

      .points-type-toggle {
        border: 1.5px solid #636c89;
        height: 30px;
        border-radius: 4px;
        margin-top: 2px;
        .MuiBottomNavigationAction-root {
          line-height: 17px;
          min-width: 52px;

          .MuiBottomNavigationAction-label {
            font-size: 12px !important;
          }
        }
      }
    }

    .header-ctn {
      padding-left: 0px;
      padding-right: 0px;
      .page-title {
        font-size: 26px;
        line-height: 22px;
      }
      .summary-card {
        ion-row:not(:last-child) {
          margin-bottom: 10px;
        }
        .summary-expand-toggle {
          text-align: center;
          .summary-expand-toggle-btn {
            color: var(--ion-text-color);
            text-transform: none;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.02em;
            height: 20px;
            ion-icon {
              color: var(--ion-text-color);
              height: 18px;
            }
          }
        }
      }
    }
    .content-ctn {
      .table-ctn {
        .MuiTableCell-root {
          padding: 8px;
        }
      }

      .filters, .filters-row {
        font-size: 12px;
        ion-select, .MuiFormControl-marginNormal {
          height: 32px !important;
          margin-top: 0;
        }

        .MuiInputBase-root .MuiInputBase-input {
          padding: 0 12px;
          font-size: 12px;
        }

        .search-filter {
          display: none;
        }
      }
    }
  }
}