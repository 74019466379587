.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      .MuiDialogTitle-root {
        // background: var(--dialog-header-background);
        // color: var(--ion-text-light);
        background: var(--ion-text-light);
        color: var(--dialog-header-background);
        text-align: center;
        height: 80px;
        .MuiTypography-root {
          color: var(--dialog-header-background) !important;
        }
      }

      .MuiIconButton-root {
        position: absolute;
        top: 8px;
        right: 12px;
        opacity: 1;
        color: var(--dialog-header-background) !important;
      }

      .MuiDialogContent-root {
        background: var(--dialog-content-background);
        padding: 0px 28px !important;
        overflow-y: initial;
        .MuiButton-containedPrimary {
          background-color: var(--ion-color-primary);
          color: var(--ion-color-primary-contrast);
          text-transform: none;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .MuiButton-containedPrimary:hover {
          background-color: var(--ion-color-primary-shade);
        }
      }
    }
  }
}
